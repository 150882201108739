import React from 'react';
import Card from 'react-bootstrap/Card';
import { ImPointRight } from 'react-icons/im';

function AboutCard() {
  return (
    <Card className='quote-card-view'>
      <Card.Body>
        <blockquote className='blockquote mb-0'>
          <p style={{ textAlign: 'justify' }}>
            Hi Everyone, I am <span className='purple'>Ruja </span>
            from <span className='purple'> Lalitpur.</span>
            <br />
            I am currently employed as a Project Manager at Bajra.
            <br />
            I have completed Bsc CSIT.
            <br />
            <br />
            Apart from project management, some other activities that I love to
            do!
          </p>
          <ul>
            <li className='about-activity'>
              <ImPointRight /> Playing Games
            </li>
            <li className='about-activity'>
              <ImPointRight /> Travelling
            </li>
            <li className='about-activity'>
              <ImPointRight /> Guffing
            </li>
          </ul>

          <p style={{ color: 'rgb(155 126 172)' }}>
            "If everything around you seems dark, look again, you may be the
            light"{' '}
          </p>
          <footer className='blockquote-footer'>Ruja</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
