import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import myImg from '../../Assets/girlcoffee.png';
import Tilt from 'react-parallax-tilt';
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';

function Home2() {
  return (
    <Container fluid className='home-about-section' id='about'>
      <Container>
        <Row>
          <Col md={8} className='home-about-description'>
            <h1 style={{ fontSize: '2.6em' }}>
              LET ME <span className='purple'> INTRODUCE </span> MYSELF
            </h1>
            <p className='home-about-body'>
              I am passionate about project management and have gained
              substantial knowledge and experience in this field.
              <br />
              <br />I am proficient in using project management tools like
              <i>
                <b className='purple'> Jira, Trello, and Asana. </b>
              </i>
              <br />
              <br />
              My interests lie in implementing new &nbsp;
              <i>
                <b className='purple'>Project Management methodologies </b> and
                also in areas related to{' '}
                <b className='purple'>Agile and Scrum.</b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my skills in managing projects
              with <b className='purple'>MS Project</b> and
              <i>
                <b className='purple'> Modern Project Management Tools</b>
              </i>
              &nbsp; like
              <i>
                <b className='purple'> Basecamp and Monday.com</b>
              </i>
            </p>
          </Col>
          <Col md={4} className='myAvtar'>
            <Tilt>
              <img src={myImg} className='img-fluid ' alt='avatar' />
            </Tilt>
          </Col>

          <Col md={4} className='myAvtar'>
            <Tilt>
              <img
                src={'https://avatars.githubusercontent.com/u/103172350?v=4'}
                width={250}
                className='img-fluid magic'
                alt='avatar'
                style={{ borderRadius: '50%' }}
              />
            </Tilt>
          </Col>

          {/* <Col md={4} className='myAvtar ruyu'>
            <Tilt>@RUYU</Tilt>
          </Col> */}
        </Row>
        <Row>
          <Col md={12} className='home-about-social'>
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className='purple'>connect </span>with me
            </p>
            <ul className='home-about-social-links'>
              <li className='social-icons'>
                <a
                  href='https://github.com/rujja'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'
                >
                  <AiFillGithub />
                </a>
              </li>

              <li className='social-icons'>
                <a
                  href='https://www.linkedin.com/in/...'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour  home-social-icons'
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className='social-icons'>
                <a
                  href='https://www.instagram.com/rujja'
                  target='_blank'
                  rel='noreferrer'
                  className='icon-colour home-social-icons'
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
